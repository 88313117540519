.App {
  text-align: center;
  padding: 0;
  margin: 0;

}

.carousel1 {
  width: 731;
  height:250 
}

.slideshow {
  position: relative;
  height: 70vh;
}

.slideshowSlider {
  white-space:normal;
  
}
.carousel-images {
  position: relative;
  border-radius: 10px;
  height: 400px;
  max-width: 650px;
  margin: auto;
  overflow: hidden;
}
.carousel-images img {
  width: 99%;
  height: 99%;
  border-radius: 8px;
  border: #ff00008e solid 2px;
}
.slide_direction {
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  background-color: #fb666675;
  color: #fff;
  padding: 10px 8px 8px 13px;
  margin: 0 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 10px;
  height: 25px;
  width: 25px;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.carousel-indicator {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.slide {
  display:inline-block;
  height: 0px;
  width: 100%;
  border-radius: 40px;
}

.removespace {
  margin-bottom: -5px
}

.Hero {
  background-image: linear-gradient(purple, gold);
  height: 50vh;
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  position:relative
}

.Navbar {
  margin-top: 0;
  background-color: gold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

.Footer {
  background-color: purple;
  color: white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: calc(2px + 2vmin);
  max-width: 100%;
  height: 15vh;
  justify-content: center;
  margin-bottom: -5px;
  padding: 0
}


#logo_img {
  height: 100%;
}

.App-logo {
  height: 40vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mainImage{
  width: 15%;
  height: 18vh;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media  (max-width:1366px) {
  .App{
    width: 100%;
  }
  .Hero{
    width: 100%;
  }
  .Footer {
    width: 100%;
  }

}
@media  (max-width:1024px) {
  .App{
    width: 100%;
  }
  .Hero{
    width: 100%;
  }

  .Footer{
    width: 100%;
  }
}
@media  (max-width:750px) {
  .App{
    width: 100%;
  }
  .Hero{
    width: 100%;
  }
  #logo_img{
    width: 100%;
  }
  .Footer .mainImage{
    width: 50%;
    height: 50px;
  }
}
@media  (max-width:480px) {
  .App{
    width: 100%;
  }
  .Hero{
    width: 100%;
    height: 80%;
  }
  #logo_img{
    width: 100%;
  }
  .Footer .mainImage{
    width: 100%;
    height: 100px;
  }
}
@media  (max-width: 200px) {
  .App{
    width: 100%;
  }
  .Hero{
    width: 100%;
    height: 80%;
  }
  #logo_img{
    width: 100%;
  }
  .Footer .mainImage{
    width: 100%;
    height: 100px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(90deg);
  }
}

.font_alconica {
  font-family: alconica;
}

.font_tangerine {
  font-family: tangerine;

}
.fontSize75px{
  font-size: 75px;
}
.fontSize25px{
  font-size: 25px;
}
.font_trattatello {
  font-family: norcian;
 
}

.color1 {
  color: purple;
}


.color2 {
  color: purple
}

.grow {
  font-family: 'Merienda';
  color: gold;
  font-style: oblique;
  font-weight: bolder;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 2s;
  transition-property: -moz-transform;
  transition-duration: 1s;
  animation-name: grow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes grow {
  from {
    -webkit-transform: scale(1);
  }

  to {
    -webkit-transform: scale(2.333);
  }
}

@-moz-keyframes grow {
  from {
    -moz-transform: scale(1);
  }

  to {
    -moz-transform: scale(2.333);
  }
}

@keyframes grow {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(2.333);
  }
}

.carousel {
  width: 60%;
  height: 700px;
  background-color: black;
}

.carouselInner {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.carouselInner .left {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}

.carouselInner .center {
  flex: 80%;
  height: 100%;
  display: grid;
  place-items: center;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  text-align-last: center;
}

h1 {
  font-size: 5rem;
}

p {
  font-size: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

h1, p {
  background-color: rgb(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 9px;
}

.carouselInner .right {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}